import { MAP_ZOOM_BREAKPOINTS } from "../../../../services/mapManagement/constants";
import {
    BADGE_VARIANT,
    DEVICE_ICON_GRAPHICS,
    ICON_VARIANT,
    IconColorOptions,
    NOTES_OVERRIDE_COLOR,
    SHADOW_STYLE,
    SIZES,
} from "./types";

/**
 * Color(s) for the icon graphic
 */
export const iconGraphicColors = {
    default: "#424242",
} as const;

/**
 * Dimmed icons coloring
 * Adds opacity to the icon to make it appear dimmed (not disabled)
 */
export const dimmedIconOpacity: number = 0.65;

/**
 * Defines colors for each icon variant
 */
export const iconColorMapping: Record<ICON_VARIANT, IconColorOptions> = {
    [ICON_VARIANT.SENSOR]: {
        shadow: "#819423",
        iconBackground: "#BBD050",
        border: "#6A7A1D",
        shadowStyle: SHADOW_STYLE.DENSE,
    },
    [ICON_VARIANT.VALVE]: {
        shadow: "#B1B0B0",
        iconBackground: "#F1EFEF",
        border: "#BBBABA",
        shadowStyle: SHADOW_STYLE.SPARSE,
    },
    [ICON_VARIANT.GROUP]: {
        shadow: "#B1B0B0",
        iconBackground: "#FDFDFD",
        shadowStyle: SHADOW_STYLE.SPARSE,
    },
} as const;

/**
 * Defines colors that can be overridden by the notes field
 * TODO: color via notes is included to support legacy setups and will be replaced with a proper system for custom icon colors. ref: SWD-595
 */
export const notesColorMapping: Record<NOTES_OVERRIDE_COLOR, IconColorOptions> = {
    [NOTES_OVERRIDE_COLOR.BLUE]: {
        shadow: "#1A5AAD",
        iconBackground: "#4A90E2",
        border: "#3A7BCE",
    },
    [NOTES_OVERRIDE_COLOR.TEAL]: {
        shadow: "#008C76",
        iconBackground: "#00BFA5",
        border: "#00A68E",
    },
    [NOTES_OVERRIDE_COLOR.YELLOW]: {
        shadow: "#B7950B",
        iconBackground: "#F1C40F",
        border: "#D4AC0D",
    },
    [NOTES_OVERRIDE_COLOR.PURPLE]: {
        shadow: "#7B1FA2",
        iconBackground: "#9C27B0",
        border: "#8E24AA",
    },
    [NOTES_OVERRIDE_COLOR.PINK]: {
        shadow: "#C2185B",
        iconBackground: "#EC407A",
        border: "#D81B60",
    },
    [NOTES_OVERRIDE_COLOR.BROWN]: {
        shadow: "#5D4037",
        iconBackground: "#795548",
        border: "#6D4C41",
    },
    [NOTES_OVERRIDE_COLOR.INDIGO]: {
        shadow: "#283593",
        iconBackground: "#5C6BC0",
        border: "#3F51B5",
    },
};

/**
 * Disabled icons coloring
 * Uses a semi-transparent gray to indicate a disabled state
 */
export const disabledIconColorMapping: IconColorOptions = {
    shadow: "#B1B0B0",
    iconBackground: "#D9D9D9",
    border: "#D9D9D9",
    shadowStyle: SHADOW_STYLE.SPARSE,
};

/**
 * Defines colors for each badge variant
 */
export const badgeColorMapping: Record<BADGE_VARIANT, { bg: string; border: string }> = {
    [BADGE_VARIANT.ERROR]: {
        bg: "#F04438",
        border: "#FDFDFD",
    },
    [BADGE_VARIANT.INFO]: {
        bg: "#F4B400",
        border: "#FDFDFD",
    },
    [BADGE_VARIANT.WARNING]: {
        bg: "#F79009",
        border: "#FDFDFD",
    },
} as const;

export const iconMapZoomScaling: Record<keyof typeof MAP_ZOOM_BREAKPOINTS, number> = {
    [MAP_ZOOM_BREAKPOINTS.DEVICE]: 1.25,
    [MAP_ZOOM_BREAKPOINTS.ZONE]: 1,
    [MAP_ZOOM_BREAKPOINTS.FIELD]: 1,
    [MAP_ZOOM_BREAKPOINTS.FARM]: 1,
    [MAP_ZOOM_BREAKPOINTS.REGION]: 1,
    [MAP_ZOOM_BREAKPOINTS.WORLD]: 1,
} as const;

export const iconSizes: Record<SIZES, number> = {
    [SIZES.XS]: 12,
    [SIZES.SM]: 18,
    [SIZES.MD]: 24,
    [SIZES.LG]: 30,
    [SIZES.XL]: 36,
} as const;

export const iconGraphicSizeScaling: Record<DEVICE_ICON_GRAPHICS, number> = {
    [DEVICE_ICON_GRAPHICS.GROUP]: 0.4,
    [DEVICE_ICON_GRAPHICS.MANUAL_MODE]: 0.5,
    [DEVICE_ICON_GRAPHICS.SENSOR]: 0.75,
    [DEVICE_ICON_GRAPHICS.VALVE]: 0.75,
} as const;

/**
 * Manually measured icon size for group menu items, to account for variable size from progress ring
 *
 * Sort of hacky, consider a rework that doesnt rely on manual measurement
 */
export const menuIconSize = 26;
export const menuIconSizeWithProgressRing = 33;
