/*
 * //TODO: this conditional v1 <-> v2 export is only to allow for feature flagging of the icons. Remove once icons are fully implemented
 */
// eslint-disable-next-line max-classes-per-file
import MoistureSensorMarkerIcon from "../../../components/icons/MapIcons/MoistureSensorMarkerIcon";
import GlobalOptions from "../../../utils/GlobalOptions";
import DeviceMapEntity from "./DeviceMapEntity";
import DeviceMapEntityV2 from "./DeviceMapEntityV2";


class ThirdPartyDeviceMapEntityV1 extends DeviceMapEntity {
    status = "Unknown";

    pickIcon() {
        this.icon = MoistureSensorMarkerIcon;
    }
}

class ThirdPartyDeviceMapEntityV2 extends DeviceMapEntityV2 {
    status = "Unknown";

    pickIcon() {
        this.icon = MoistureSensorMarkerIcon;
    }
}

export const ThirdPartyDeviceMapEntity = 
    GlobalOptions.devFeatureFlags.enableNewIcons ?
        ThirdPartyDeviceMapEntityV2 :
        ThirdPartyDeviceMapEntityV1;
