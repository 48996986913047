import { DeviceConfigurationsByType } from "verditypes/dist/Configurations/DeviceConfiguration";

import BlockValveMarkerIcon from "../../../components/icons/MapIcons/BlockValveMarkerIcon";
import DeviceMarkerIcon from "../../../components/icons/MapIcons/DeviceMarkerIcon";
import MoistureSensorMarkerIcon from "../../../components/icons/MapIcons/MoistureSensorMarkerIcon";
import TensiometerMarkerIcon from "../../../components/icons/MapIcons/TensiometerMarkerIcon";
import WatermarkMarkerIcon from "../../../components/icons/MapIcons/WatermarkMarkerIcon";
import { FunctionalDeviceInfoCard } from "../../../components/specialized/infoCards/DeviceInfoCard/FunctionalDeviceInfoCard";
import { GenerateUID } from "../../../utils/HelperFunctions";
import MapEntityBase from "./MapEntityBase";

const allDeviceEntities = new Set();

export default class DeviceMapEntityV2 extends MapEntityBase {
    static infoCardClass = FunctionalDeviceInfoCard;

    distanceToNearest = 100000000;

    /**
     *@type {HistoricalDataBase}
     */
    moistureDataBase;

    infoCardClass = FunctionalDeviceInfoCard;

    warningText = undefined;

    warningToShow = undefined;

    manualModeWarning = false;

    /**
     *  @type {irrigationDevice}
     */
    model;

    sensorOutOfDate = false;
    /**
     * @param {irrigationDevice} IrrigationDevice
     */

    get type() {
        return this.model.type || this.model.deviceType;
    }

    get DOMClassNames() {
        const initialClassNames = super.DOMClassNames;
        if (this.manualModeWarning) {
            initialClassNames.push("ME_ManualMode");
        }
        return initialClassNames;
    }

    constructor(IrrigationDevice, renderImmediately = true) {
        super(IrrigationDevice, GenerateUID("DevE"), false);
        this.lat = this.model.lat;
        this.long = this.model.long;
        allDeviceEntities.add(this);
        this.pickIcon();
    }

    // TODO: Required because this sets the icon used by the device card. Should decouple from the card and remove this
    // TODO update these to use the new icons
    pickIcon() {
        const deviceType = this.type;
        this.icon = DeviceMarkerIcon;
        if (DeviceConfigurationsByType[deviceType]?.valveType === "block") {
            this.icon = BlockValveMarkerIcon;
        } else if (deviceType === "msense") {
            this.icon = MoistureSensorMarkerIcon;
        } else if (deviceType === "moisture2v") {
            this.icon = DeviceMarkerIcon;
        }
        if (DeviceConfigurationsByType[deviceType]?.valveCount === 0) {
            if (this.model.connectedSensors && this.model.connectedSensors.some((s) => s.sensorType === "irrometer")) {
                this.icon = TensiometerMarkerIcon;
            } else if (
                this.model.connectedSensors &&
                this.model.connectedSensors.some((s) => s.sensorType === "watermark")
            ) {
                this.icon = WatermarkMarkerIcon;
            }
        }
    }

    setStatePreRender() {
        this.renderState.beingRendered = true;
    }

    setStatePostRender() {
        this.renderState.onMap = true;
        this.renderState.beingRendered = false;
        this.updateFocusState();
    }

    remove() {
        super.remove();
        allDeviceEntities.delete(this);
    }
}
