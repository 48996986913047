import * as turf from "@turf/turf";

import type MapEntityBase from "../mapEntities/MapEntityBase";
import { findClusterCenter } from "./clustering";
import { MapItems } from "./constants";
import type { Cluster, ClusterMapItem } from "./types";

/**
 * Categories of models that are included in the icon layer
 */
const CategoriesInIconLayer = ["blockValve", "irrigationDevice", "thirdPartyDevice", "SensoterraMoisture"];
export const mapEntityBelongsInIconLayer = ({ mapEntity }: { mapEntity: MapEntityBase }) =>
    Boolean(mapEntity?.model && CategoriesInIconLayer.includes(mapEntity?.model.category));

/**
 * Creates the cluster map items from the clustered FeatureCollection
 */
export const createClusterMapItems = (
    clusteredFeatures: Cluster,
): {
    clusterMapItems: ClusterMapItem[];
    clusterKeys: { [key: string]: number | undefined };
} => {
    const clusterMapItems: ClusterMapItem[] = [];
    // Maps member's ids to cluster ids if clustered
    const clusterKeys: { [key: string]: number | undefined } = {};

    turf.clusterEach(clusteredFeatures, "cluster", (cluster, clusterKey) => {
        // All features in cluster have same dbscan type, so just use first one
        const firstFeature = cluster.features[0];

        // Ignore noise points since they are individual devices (i.e. not part of a group)
        if (firstFeature.properties?.dbscan === "noise") return;

        // Find the centroid of cluster's members
        const [long, lat] = findClusterCenter(cluster);

        // Mark device ids as part of a cluster
        turf.featureEach(cluster, (feature) => {
            if (feature?.properties?.id && clusterKey) {
                clusterKeys[feature.properties.id] = clusterKey;
            }
        });

        // Compose ID based on lat, long, and sorted device IDs
        const containedDeviceIds = new Set<string>();
        turf.featureEach(cluster, (feature) => {
            if (feature?.properties?.id) {
                containedDeviceIds.add(feature.properties.id);
            }
        });

        const id = `${lat}-${long}-${Array.from(containedDeviceIds).sort().join(",")}`;

        clusterMapItems.push({
            id,
            clusterKey,
            type: MapItems.CLUSTER,
            lat,
            long,
            cluster: cluster,
        } as ClusterMapItem);
    });

    return { clusterMapItems, clusterKeys };
};
