export const DayLookup = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const ShortDayLookup = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const LongMonthLookup = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const ShortMonthLookup = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

Object.defineProperty(Date, "minute", {
    value: 1000 * 60,
});
Object.defineProperty(Date, "hour", {
    value: 1000 * 60 * 60,
});
Object.defineProperty(Date, "day", {
    value: 1000 * 60 * 60 * 24,
});
export function TimeToUTCConversion(t) {
    return new Date(
        Date.UTC(
            t.getUTCFullYear(),
            t.getUTCMonth(),
            t.getUTCDate(),
            t.getUTCHours(),
            t.getUTCMinutes(),
            t.getUTCSeconds(),
        ),
    );
}

/**
 *
 * @param {Date} DateInput
 */
export function getDateOfStartOfWeek(date) {
    const d = new Date(date.valueOf());
    d.setDate(d.getDate() - d.getDay());
    d.setHours(0, 0, 0, 0);
    return d;
}

const ExistingUIDs = {
    id: 0,
};
export function GenerateUID(prefix = "id") {
    if (!ExistingUIDs[prefix]) {
        ExistingUIDs[prefix] = 0;
    }
    ExistingUIDs[prefix]++;
    return `${prefix}${ExistingUIDs[prefix]}`;
}

export function GetNearestAngle(original, newAngle, period = Math.PI * 2) {
    const FullCircles = Math.floor(original / period);
    const Candidates = [
        newAngle + FullCircles * period,
        newAngle + (FullCircles - 1) * period,
        newAngle + (FullCircles + 1) * period,
    ];
    const Results = [
        Math.abs(Candidates[0] - original),
        Math.abs(Candidates[1] - original),
        Math.abs(Candidates[2] - original),
    ];
    if (Results[0] < Results[1] && Results[0] < Results[2]) {
        return Candidates[0];
    }
    if (Results[1] < Results[2] && Results[1] < Results[0]) {
        return Candidates[1];
    }
    if (Results[2] < Results[0] && Results[2] < Results[1]) {
        return Candidates[2];
    }
    return original;
}

export function timeout(ms) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, ms));
}
export async function sleep(delay, fn, ...args) {
    await timeout(delay);
    if (fn) {
        return fn(...args);
    }
    return true;
}

/**
 * Repeatedly tries to call fn until it returns true or the attempt limit is reached. Will return the output of fn
 * @param {int} delay ms of delay between attempts
 * @param {function} fn function to be called
 * @param {int} attemptLimit max number of attempts
 * @param args arguments for fn
 * @return {Promise<*>}
 */
export async function AttemptOnDelay(delay, attemptLimit, fn, ...args) {
    let attempts = 0;
    let result = false;
    while (attempts < attemptLimit && !result) {
        attempts++;
        await timeout(delay);
        try {
            result = fn(...args);
        } catch (e) {
            // pass
        }
    }
    return result;
}

/**
 * Determine if something is actually a number, unlike isNaN which determines if something resembles a number.
 * This considers the following as numbers:
 *  • 12.3
 *  • 0
 *  • Number.POSITIVE_INFINITY
 *  • Number.NEGATIVE_INFINITY
 *  • -0
 *
 * This considers the following as **not** being numbers:
 *  • null
 *  • undefined
 *  • ""
 *  • Number.NaN
 *  • "123"
 *  • true
 *  • false
 *  • {}
 *  • []
 * @param {any} x
 * @return {boolean}
 */
export function isNumber(x) {
    return x !== undefined && x !== null && typeof x === "number" && !Number.isNaN(x);
}
