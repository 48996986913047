export const MAP_ZOOM_BREAKPOINTS = {
    WORLD: "WORLD",
    REGION: "REGION",
    FARM: "FARM",
    FIELD: "FIELD",
    ZONE: "ZONE",
    DEVICE: "DEVICE",
} as const;

// Higher zoom number is more zoomed in
export const getMapBreakpoint = (zoom: number) => {
    if (zoom > 21) {
        return MAP_ZOOM_BREAKPOINTS.DEVICE;
    }
    if (zoom > 19) {
        return MAP_ZOOM_BREAKPOINTS.ZONE;
    }
    if (zoom > 18) {
        return MAP_ZOOM_BREAKPOINTS.FIELD;
    }
    if (zoom > 16) {
        return MAP_ZOOM_BREAKPOINTS.FARM;
    }
    if (zoom > 12) {
        return MAP_ZOOM_BREAKPOINTS.REGION;
    }
    // 12.5 and below
    return MAP_ZOOM_BREAKPOINTS.WORLD;
};

// Gets the number meters per pixel at a given zoom level and map center
export const getMetersPerPixel = (zoom: number, mapCenter: { lat: number; lng: number }) =>
    (40075016.686 * Math.abs(Math.cos((mapCenter.lat * Math.PI) / 180))) / 2 ** (zoom + 8);
