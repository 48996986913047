export enum Z_INDEX_OFFSETS {
    ICON_HOVERED = 100,
}

/**
 * Types of map items
 */
export enum MapItems {
    DEVICE = "device",
    CLUSTER = "cluster",
    OTHER = "other",
}
